import React from "react";
import Layout from "./layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BlankButton from "../ui/BlankButton";
import { Link } from "gatsby";

const Dealers = ({ data }) => {
  return (
    <>
      <Layout>
        <div className="my-16 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto">
          <div className="mx-6 flex flex-col space-y-2">
            <h1 className="text-3xl md:text-4xl font-semibold text-gray-700 text-center">
              {data.prismicDealers.data.dealer_name.text}
            </h1>
            <h5 className="text-lg md:text-xl font-light text-gray-500 text-center">
              {data.prismicDealers.data.shorthand_address}
            </h5>
          </div>

          <GatsbyImage
            image={getImage(data.prismicDealers.data.dealership_image)}
            className="my-8 w-full aspect-w-16 aspect-h-9 bg-cover md:rounded-xl md:shadow"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-6 md:mx-0 lg:gap-x-4">
            <div className="flex flex-col space-y-2 py-6">
              <h2 className="text-2xl font-semibold text-gray-700">
                Store Location
              </h2>
              <p className="text-base xl:text-lg text-gray-500 font-light">
                {data.prismicDealers.data.address}
              </p>
            </div>
            <div className="flex flex-col space-y-2 py-6">
              <h2 className="text-2xl font-semibold text-gray-700">
                Contact Details{" "}
              </h2>
              <p className="text-base xl:text-lg text-gray-500 font-light">
              <a href={"mailto:" + data.prismicDealers.data.e_mail_address}>{data.prismicDealers.data.e_mail_address}</a>

              </p>
              <p className="text-base xl:text-lg text-gray-500 font-light">
                <a href={"tel:8208594041"}>+918208594041</a>
              </p>
              <a href = {"tel:+91" + data.prismicDealers.data.mobile_number}>
                <BlankButton text = "Contact Dealer" />
              </a>
            </div>
            <div className="flex flex-col space-y-4 py-6">
              <h2 className="text-2xl font-semibold text-gray-700">
                How can we help you?{" "}
              </h2>
              <Link to = "/dealers/">
                <BlankButton text = "Locate Another Dealer" />
              </Link>
              <Link to = "/coming-soon">
                <BlankButton text = "Register for Test Ride" />
              </Link>
            </div>
          </div>
          

          <div className="mt-10 px-6 md:px-0">
            <h3 className="text-xl md:text-3xl font-bold text-gray-700 mb-4 md:mb-6 md:leading-[45px]">
              Dealership Gallery
            </h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {data.prismicDealers.data.dealership_carousel.map((item) => (
              <div>
                <GatsbyImage
                  image={getImage(item.dealership_images)}
                  className="aspect-w-4 aspect-h-3 h-full w-full rounded bg-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query dealers($slug: String) {
    prismicDealers(data: { slug: { eq: $slug } }) {
      data {
        address
        dealer_location {
          latitude
          longitude
        }
        dealer_name {
          text
        }
        dealership_carousel {
          dealership_images {
            gatsbyImageData
          }
        }
        dealership_image {
          gatsbyImageData
        }
        e_mail_address
        mobile_number
        shorthand_address
      }
    }
  }
`;

export default Dealers;
